import React from 'react';

import '../styles/components/pages/BienvenidaPage.css';

const BienvenidaPage = (props) => {
    return (
        <div id='welcome' className='container-background'></div> 
    );
}

export default BienvenidaPage;